<script>
export default {
  props: {
    colors: {
      required: true,
      type: Array
    }
  },
  computed: {
    colorString () {
      let cs = ''
      this.colors.forEach((color, index) => {
        cs = cs.concat(`, ${color} ${index * (100 / (this.colors.length - 1))}%`)
      })
      return cs
    }
  },
  render (h) {
    const container = content => h('div', {
      style: {
        display: 'flex',
        flexDirection: 'column',
        margin: '4px 0'
      }
    }, content)

    const textContainer = content => h('div', {
      style: {
        display: 'flex',
        justifyContent: 'space-between'
      }
    }, content)

    const gradient = () => h('div', {
      style: {
        background: `linear-gradient(to right${this.colorString})`,
        height: '30px',
        width: '100%'
      }
    })

    return container([
      textContainer([
        this.$slots.startLabel,
        this.$slots.endLabel
      ]), gradient()
    ])
  }
}
</script>
