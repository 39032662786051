<template lang="html">
  <a
    ref="info" tabindex="0"
    @click.prevent="onClick"
    @blur="onBlur"
  >
    <i class="fa fa-question-circle" label-style />
    <slot slot="info" name="description" />
  </a>
</template>

<script>
export default {
  props: {
    description: {},
    trigger: String,
    placement: {
      type: String,
      default: 'left'
    }
  },
  data () {
    return {
      show: false,
      slotDescription: null
    }
  },
  computed: {
    popoverContent () {
      if (this.description === undefined && this.slotDescription !== null) {
        return `<span>${this.slotDescription}</span>`
      }
      if (!Array.isArray(this.description)) {
        return `<span>${this.description}</span>`
      }
      if (this.description.length === 1) {
        return `<span>${this.description[0]}</span>`
      }
      return `<ul>${this.description.map(part => `<li>${part}</li>`).join('')}</ul>`
    }
  },
  methods: {
    onClick () {
      this.show = !this.show
      $(this.$refs.info).popover(this.show ? 'show' : 'hide')
    },
    onBlur () {
      this.show = false
      $(this.$refs.info).popover('hide')
    }
  },
  mounted () {
    this.slotDescription = this.$refs.info.getElementsByTagName('span')[0] !== undefined ? this.$refs.info.getElementsByTagName('span')[0].innerHTML : null
    const options = {
      trigger: this.trigger ? this.trigger : 'manual',
      placement: this.placement,
      content: () => this.popoverContent,
      html: true,
      container: 'body'
    }
    $(this.$refs.info).popover(options)
  },
  beforeDestroy () {
    $(this.$refs.info).popover('destroy')
  }
}
</script>

<style lang="scss" scoped>
.label-style {
}
a:hover {
  cursor: pointer;
}
a:focus {
  outline: 0;
}
</style>
