<template>
  <div>
    <FrsRouterBackLink :target="'phbb'">
      <template #text>
        <IxRes>phbb.buttons.backToPhbb</IxRes>
      </template>
    </FrsRouterBackLink>

    <ContentWrapper>
      <template #title>
        <IxRes>phbb.wizard.stepSixTitle</IxRes>
        <div class="sub-title">
          <IxRes>phbb.wizard.label.partThree</IxRes>:
          <IxRes>phbb.wizard.stepSixAction.partThree</IxRes>
        </div>
      </template>

      <LimeApplicationInput v-if="step==='start'" />

      <PhBbMapLayer v-if="caoApplicationMapId && ['start', 'calculating'].includes(step)" :map="caoApplicationMap" :opacity="1" />

      <div v-if="step==='calculating'">
        <div><IxRes>phbb.create.application.label.calculatingLimeApplicationMapPleaseWait</IxRes></div>
        <div class="loading-indicator-box bottom-margin">
          <FrsLoadingIndicator loading />
        </div>
        <div>
          <IxButton large icon="times" @click="onCancelCalculation">
            <IxRes>phbb.create.limeApplication.button.cancelCalculation</IxRes>
          </IxButton>
        </div>
      </div>

      <LimeApplicationResult v-if="step==='calculated'" />

      <template #footer>
        <div v-if="step==='start'">
          <IxButton large next @click="onStartCalculation">
            <IxRes>phbb.wizard.button.startCreation</IxRes>
          </IxButton>
        </div>
      </template>
    </ContentWrapper>
  </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex'
import {notifications} from 'src/js/infrastructure'

import FrsRouterBackLink from '@frs/components/base/FrsRouterBackLink'
import FrsLoadingIndicator from '@frs/components/base/FrsLoadingIndicator'
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'
import ContentWrapper from '@frs/components/phbb/widgets/ContentWrapper'
import StandardRulesMixin from '@components/forms/StandardRulesMixin'
import IxButton from '@components/IxButton'
import FormPartMixin from '@components/forms/FormPartMixin'

import LimeApplicationInput from './LimeApplicationInput'
import LimeApplicationResult from './LimeApplicationResult'

export default {
  components: {
    ContentWrapper,
    FrsRouterBackLink,
    FrsLoadingIndicator,
    PhBbMapLayer,
    IxButton,
    LimeApplicationInput,
    LimeApplicationResult
  },
  mixins: [
    FormPartMixin,
    StandardRulesMixin
  ],
  computed: {
    ...mapState('fieldRecordSystem/phbb', {
      step: state => state.ui.limeApplication.limeApplicationStep,
      useExistingFertilizer: state => state.data.limeApplication.useExistingFertilizer,
      caoApplicationMapId: state => state.data.limeApplication.caoApplicationMapId
    }),
    ...mapGetters('fieldRecordSystem', [
      'fieldId'
    ]),
    ...mapGetters('fieldRecordSystem/phbb', [
      'soilMaps',
      'caOApplicationMaps'
    ]),
    caoApplicationMap () {
      if (!this.caoApplicationMapId) {
        return null
      }
      const map = this.soilMaps.find(x => x.id === this.caoApplicationMapId)

      if (!map || !map.zones || !map.zones.length) {
        return null
      }

      return map
    },
    isValid () {
      return this.state === 'success'
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadSoilMapsForField'
    ]),
    ...mapActions('fieldRecordSystem/phbb', [
      'createLimeApplicationMap',
      'saveCustomFertilizer'
    ]),
    ...mapMutations('fieldRecordSystem/phbb', [
      'setDefaultState',
      'setLimeApplicationStep',
      'setCaoApplicationMapId'
    ]),
    async onStartCalculation () {
      this.validate()
      await this.$nextTick()

      if (!this.isValid) return

      this.setLimeApplicationStep('calculating')

      if (!this.useExistingFertilizer) {
        await this.saveCustomFertilizer()
      }

      await this.createLimeApplicationMap()
      notifications.success(this.$i18n.translate('phbb.create.limeApplication.notifications.mapCreated'))
      this.setLimeApplicationStep('calculated')
    },
    onCancelCalculation () {
      this.setLimeApplicationStep('start')
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.setDefaultState()
      await vm.loadSoilMapsForField(vm.fieldId)
      if (vm.caOApplicationMaps.length > 0) {
        vm.setCaoApplicationMapId(vm.caOApplicationMaps[0].id)
      }
    })
  }
}
</script>

<style lang="scss" scoped>
.bottom-margin {
  margin-bottom: 10px;
}

.loading-indicator-box {
  position: relative;
  width: 100%;
  height: 150px;
}
</style>
