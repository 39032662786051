<template>
  <div>
    <h4>
      <IxRes>phbb.create.limeApplication.label.applicationRate</IxRes>
    </h4>

    <NumericInput
      v-model="minAmount" integer
      name="minAmount"
      :readonly="!editApplicationRate"
      unit="kg/ha"
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.label.applicationRate.minAmount</IxRes>
      </template>
    </NumericInput>

    <div v-if="editApplicationRate" class="adjust-application-value-simple-switch">
      <SimpleSwitch v-model="adjustLowerLimit" name="adjustLowerLimit">
        <IxRes>phbb.create.limeApplication.label.setValuesBelowMinimumToMinimum</IxRes>

        <template #offLabel>
          <IxRes>phbb.create.limeApplication.label.setValuesBelowMinimumToZero</IxRes>
        </template>
      </SimpleSwitch>
    </div>

    <NumericInput
      v-model="maxAmount" integer
      name="maxAmount"
      :readonly="!editApplicationRate"
      unit="kg/ha"
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.label.applicationRate.maxAmount</IxRes>
      </template>
    </NumericInput>

    <NumericInput
      unit="kg/ha" :value="meanValue"
      name="meanValue"
      readonly
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.label.applicationRate.meanValue</IxRes>
      </template>
    </NumericInput>

    <NumericInput
      v-model="totalRequirement" integer
      :readonly="!editApplicationRate"
      name="totalRequirement"
      unit="kg"
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.label.applicationRate.totalRequirement</IxRes>
      </template>
    </NumericInput>

    <HelpBox v-if="supportedCombinationsDisplay" type="warning">
      <IxRes>phbb.create.limeApplication.notifications.supportedCombinations</IxRes>
    </HelpBox>

    <div class="edit-button">
      <IxButton
        v-if="!editApplicationRate" large
        icon="edit" class="bottom-margin"
        @click="toggleEditApplicationRate"
      >
        <IxRes>phbb.wizard.button.editApplicationRate</IxRes>
      </IxButton>
      <IxButton
        v-else large
        icon="check" class="bottom-margin"
        @click="saveAndRecalculate"
      >
        <IxRes>phbb.wizard.button.saveAndRecalculate</IxRes>
      </IxButton>
    </div>

    <GradientScale :colors="colors">
      <template #startLabel>
        <span>min.({{ minAmount }}kg)</span>
      </template>
      <template #endLabel>
        <span>max.({{ maxAmount }}kg)</span>
      </template>
    </GradientScale>

    <PhBbMapLayer :map="limeApplicationMap" :opacity="1" />

    <div class="bottom-margin">
      <IxRes>phbb.create.limeApplication.label.mapCreatedSuccessfully</IxRes>
    </div>
    <div>
      <IxButton large icon="download" @click="downloadMap">
        <IxRes>phbb.wizard.button.downloadMap</IxRes>
      </IxButton>
      <IxButton large class="left-margin" @click="launchMapManagement">
        <IxRes>phbb.wizard.button.launchMapManagement</IxRes>
      </IxButton>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'

import {mapFormFields} from '@helpers/vuex'
import {notifications} from 'src/js/infrastructure'

import MirrorRouteMixin from '@frs/mixins/MirrorRouteMixin'

import SimpleSwitch from '@components/forms/SimpleSwitch'
import IxButton from '@components/IxButton'
import HelpBox from '@components/help/HelpBox'
import NumericInput from '@components/forms/NumericInput'
import GradientScale from '@components/GradientScale'
import PhBbMapLayer from '@frs/components/base/PhBbMapLayer'

export default {
  components: {
    IxButton,
    HelpBox,
    NumericInput,
    GradientScale,
    PhBbMapLayer,
    SimpleSwitch
  },
  mixins: [
    MirrorRouteMixin
  ],
  data () {
    return {
      editApplicationRate: false,
      supportedCombinationsDisplay: false
    }
  },
  computed: {
    ...mapState('fieldRecordSystem/phbb', {
      limeApplicationMap: state => state.data.limeApplication.limeApplicationMap
    }),
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.limeApplication.applicationRate.minAmount',
      'data.limeApplication.applicationRate.maxAmount',
      'data.limeApplication.applicationRate.meanValue',
      'data.limeApplication.applicationRate.totalRequirement',
      'data.limeApplication.adjustLowerLimit'

    ]),
    colors () {
      return [
        '#FFD5DD',
        '#47010E'
      ]
    },
    inputCombinationIsSupported () {
      return this.onlyMin() || this.onlyMax() || this.onlyTotalRequirement() || this.onlyMinAndMax()
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/phbb', [
      'createLimeApplicationMap'
    ]),
    ...mapActions('fieldRecordSystem/download', [
      'startDownload'
    ]),
    ...mapMutations('fieldRecordSystem/phbb', [
      'setLimeApplicationStep'
    ]),
    onlyMin () {
      return this.minAmount && !this.maxAmount && !this.totalRequirement
    },
    onlyMax () {
      return !this.minAmount && this.maxAmount && !this.totalRequirement
    },
    onlyTotalRequirement () {
      return !this.minAmount && !this.maxAmount && this.totalRequirement
    },
    onlyMinAndMax () {
      return this.minAmount && this.maxAmount && !this.totalRequirement
    },
    toggleEditApplicationRate () {
      this.editApplicationRate = !this.editApplicationRate
    },
    async saveAndRecalculate () {
      this.supportedCombinationsDisplay = false

      if (!this.inputCombinationIsSupported) {
        this.supportedCombinationsDisplay = true
        return
      }
      this.setLimeApplicationStep('calculating')
      await this.createLimeApplicationMap(true)
      this.setLimeApplicationStep('calculated')
      notifications.success(this.$i18n.translate('phbb.create.limeApplication.notifications.mapCreated'))
    },
    downloadMap () {
      this.startDownload({type: 'applicationMap', ids: [this.limeApplicationMap.id]})
    },
    launchMapManagement () {
      this.$router.push({name: this.createParallelRoute('mapManagement')})
    }
  }
}
</script>

<style lang="scss" scoped>
    .bottom-margin {
    margin-bottom: 10px;
    }
    .application-rate-container {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
    .left-margin {
        margin-left: 5px;
    }
    .adjust-application-value-simple-switch, .edit-button {
      margin-top: 5px;
    }
</style>
