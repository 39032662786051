<template>
  <div>
    <VueSelectize
      v-model="caoApplicationMapId"
      class="bottom-margin" required
      :options="caoApplicationMapIds" :fields="caoApplicationMapFields"
      allow-empty
      name="applicationMapId"
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.selectize.labels.caoApplicationMap</IxRes>:
      </template>
    </VueSelectize>

    <FrsProductPicker
      v-model="fertilizerId" name="fertilizerId"
      type="fertilizer"
      :available-product-filter="availableProductFilter"
      :required="useExistingFertilizer" :readonly="!useExistingFertilizer"
    >
      <template #label>
        <IxRes>phbb.create.limeApplication.label.fertilizer</IxRes>
      </template>
    </FrsProductPicker>

    <div class="bottom-margin">
      <a class="link" @click="toggleUseExistingFilter">
        <IxRes>phbb.create.limeApplication.label.{{ !useExistingFertilizer ? 'useExistingFertilizer' : 'useCustomFertilizer' }}</IxRes>
      </a>
      <VueInfo placement="top" class="info" trigger="hover">
        <template #description>
          <IxRes>phbb.create.limeApplication.label.useExistingFertilizer_help</IxRes>
        </template>
      </VueInfo>
    </div>

    <div v-if="useExistingFertilizer" class="bottom-margin">
      <div v-if="product">
        <LabelAndMessage>
          <template #label>
            <IxRes>phbb.create.limeApplication.label.fertilizerContents</IxRes>:
          </template>
        </LabelAndMessage>
        <div class="flex-div">
          <div class="flex-item">
            CaO: {{ product.lime ? $i18n.format(product.lime / 100, 'percent') : '' }}
          </div>
          <div class="flex-item left-margin-item">
            CaCO&#8323;: {{ product.lime ? convertLimeToOxidForm(product.lime / 100) : '' }}
          </div>
        </div>
        <div class="flex-div">
          <div class="flex-item">
            MgO: {{ product.magnesium ? magnesiumOxid(product.magnesium / 100) : '' }}
          </div>
          <div class="flex-item left-margin-item">
            MgCO&#8323;: {{ product.magnesium ? convertMgToOxidForm(product.magnesium / 100) : '' }}
          </div>
        </div>
      </div>
    </div>
    <div v-else class="bottom-margin">
      <TextInput
        v-model="name" class="fertilizer-name-input"
        required
        name="fertilizerName"
      >
        <template #label>
          <IxRes>phbb.create.limeApplication.label.fertilizerName</IxRes>:
        </template>
      </TextInput>
      <div class="flex-div">
        <NumericInput
          v-model="caValue" integer
          unit="%" required
          percent-not-normalized
          name="caValue"
          class="flex-item"
        >
          <template #label>
            <IxRes>phbb.create.limeApplication.label.{{ useOxidValues ? 'caOxid' : 'caCarbonate' }}</IxRes>:
          </template>
        </NumericInput>
        <NumericInput
          v-model="mgValue" integer
          unit="%" required
          percent-not-normalized
          name="mgValue"
          class="flex-item left-margin-item"
        >
          <template #label>
            <IxRes>phbb.create.limeApplication.label.{{ useOxidValues ? 'mgOxid' : 'mgCarbonate' }}</IxRes>:
          </template>
        </NumericInput>
      </div>
      <div>
        <BsCheckbox v-model="useOxidValues" class="bottom-margin">
          <IxRes>phbb.create.limeApplication.label.useOxidValues</IxRes>
        </BsCheckbox>
      </div>
      <IxRes>phbb.create.limeApplication.label.{{ useOxidValues ? 'oxidHelpText' : 'carbonateHelpText' }}</IxRes>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import {mapFormFields} from '@helpers/vuex'

import TextInput from '@components/forms/TextInput'
import NumericInput from '@components/forms/NumericInput'
import VueSelectize from '@components/VueSelectize'
import FrsProductPicker from '@frs/components/base/FrsProductPicker'
import BsCheckbox from '@components/bootstrap/BsCheckbox'
import VueInfo from '@components/help/VueInfo'
import LabelAndMessage from '@components/forms/input-base/LabelAndMessage'
import {calculateToOxidform} from '@frs/components/basic-fertilization/oxidform-calculation'

export default {
  components: {
    BsCheckbox,
    FrsProductPicker,
    LabelAndMessage,
    NumericInput,
    TextInput,
    VueInfo,
    VueSelectize
  },
  computed: {
    ...mapFormFields('fieldRecordSystem/phbb', [
      'data.limeApplication.caoApplicationMapId',
      'data.limeApplication.fertilizerId',
      'data.limeApplication.useExistingFertilizer',
      'data.limeApplication.customFertilizer.name',
      'data.limeApplication.customFertilizer.caValue',
      'data.limeApplication.customFertilizer.mgValue',
      'data.limeApplication.customFertilizer.useOxidValues'
    ]),
    ...mapGetters('masterData', [
      'products'
    ]),
    ...mapGetters('fieldRecordSystem/phbb', [
      'caOApplicationMaps'
    ]),
    caoApplicationMapIds () {
      if (!this.caOApplicationMaps.length) return []

      return this.caOApplicationMaps.map(x => x.id)
    },
    caoApplicationMapFields () {
      return {
        value: id => id,
        text: id => this.caOApplicationMaps.find(map => map.id === id).name,
        label: id => this.$i18n.format(this.caOApplicationMaps.find(map => map.id === id).generatedAt, 'datetime')
      }
    },
    availableProductFilter () {
      return product => product.lime > 0
    },
    product () {
      return this.products[this.fertilizerId] || null
    }
  },
  methods: {
    ...mapActions('fieldRecordSystem/mapManagement', [
      'loadSoilMapDetails'
    ]),
    toggleUseExistingFilter () {
      this.useExistingFertilizer = !this.useExistingFertilizer
    },
    convertLimeToOxidForm (value) {
      // factor for conversion from CaO to CaCO3 is 1.78
      value *= 1.78
      return this.$i18n.format(value, 'percent')
    },
    convertMgToOxidForm (value) {
      let oxidForm = calculateToOxidform('magnesium', value)
      // factor for conversion from MgO to MgCO3 is 2.091
      oxidForm *= 2.091
      return this.$i18n.format(oxidForm, 'percent')
    },
    magnesiumOxid (value) {
      const oxidForm = calculateToOxidform('magnesium', value)
      return this.$i18n.format(oxidForm, 'percent')
    }
  },
  watch: {
    caoApplicationMapId: {
      immediate: true,
      handler: function (mapId) {
        if (mapId) {
          this.loadSoilMapDetails(mapId)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .flex-div {
      display: flex;
  }
  .flex-item {
    width: 100px;
  }
  .left-margin-item {
    margin-left: 15px;
  }
  .bottom-margin {
    margin-bottom: 10px;
  }
  .fertilizer-name-input {
    width: 200px;
  }
  .info{
    margin-left: 5px;
  }
  .link {
    cursor: pointer;
  }
</style>
